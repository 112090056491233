import {isNumber} from 'underscore';

import {TrackingExtras} from '../src/modules/Dashboard/types';

import {getReviewsData} from './profileUtils';

// Source: Individual | Full_Profile | Dashboard | Calendar_Landing_Page
export const getBookingSource = (asPath: string): string => {
  let bookingSource = 'Dashboard';

  if (asPath.includes('/dashboard/advisors/')) {
    bookingSource = 'Full_Profile';
  } else if (asPath.includes('/advisor/')) {
    bookingSource = 'Individual';
  } else if (asPath.includes('/schedule/') || asPath.includes('/reschedule/')) {
    bookingSource = 'Calendar_Landing_Page';
  }

  return bookingSource;
};

export const getBookingType = (
  adviceRequest: AdviceRequest,
  isReschedule: boolean,
): string => {
  let bookingType =
    adviceRequest?.meeting?.type?.indexOf('intro') !== -1
      ? 'intro'
      : 'discovery';

  if (isReschedule) {
    bookingType += '_Reschedule';
  }

  return bookingType;
};

export const getTrackingExtras = (
  match: Match,
  advisor: Advisor,
  reviews?: Review[],
): TrackingExtras => {
  let scheduleAdvisor: Advisor;
  let advisorIndex: number;
  let advisorDistance: number;

  (match?.suggestedAdvisors || []).forEach((suggested, idx) => {
    if (suggested.advisor.id === advisor.id) {
      scheduleAdvisor = {
        ...advisor,
        reviews: reviews || advisor.reviews || [],
      };
      advisorIndex = idx + 1;
      advisorDistance =
        suggested.scores &&
        isNumber(suggested.scores.distance) &&
        parseInt(`${suggested.scores.distance}`, 10);
    }
  });

  const reviewsData = getReviewsData(scheduleAdvisor);

  return {
    Advisor_Index: advisorIndex?.toString(),
    Advisor_Distance: advisorDistance?.toString(),
    Average_Score: reviewsData?.score || undefined,
    Review_Count: reviewsData?.count.toString(),
  };
};

export const createHubspotMeta = (): ObjectOfStrings => {
  if (typeof document === 'undefined') return undefined;

  const hubspotCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('hubspotutk') || cookie.includes('hutk'));

  const meta: ObjectOfStrings = {
    pageName: document.title,
    pageUri: document.URL,
  };

  if (hubspotCookie) {
    meta.hutk = hubspotCookie.split('=')[1];
  }

  return meta;
};

/**
 * Reads the client ID from the Google Analytics cookie (_ga)
 *
 * @returns {string} GA Client ID
 */
export function getGAClientId(): string {
  const cookie: Record<string, any> = {};

  document.cookie.split(';').forEach((el) => {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];

    cookie[key] = value;
  });

  return cookie._ga?.substring?.(6) || undefined;
}
