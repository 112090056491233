import {AppState, RedirectLoginOptions} from '@auth0/auth0-react';
import Router from 'next/router';

import auth0JS from './auth0Utils/auth0js';
import {createHubspotMeta, getGAClientId} from './bookingUtils';
import request from './request';

interface UserCreationResponse {
  user: Prospect;
  password: string;
}

/* Copied from: `/node_modules/@auth0/auth0-react/src/auth0-context.tsx` */
export type LoginWithRedirect = (
  options?: RedirectLoginOptions<AppState>,
) => Promise<void>;

export type WebAuthMatchProps = (
  options: {
    body: ObjectOfAny;
    loginWithRedirect: LoginWithRedirect;
    testingFormVersion?: string;
    password?: string;
  },
  callback: (error?: any) => void,
) => void;

/**
 * Every third party provides custom query params when embedding our form experience
 * we must pass those parameters to the dashboard when the user lands in the match
 */
export const getHostedParams = (
  routerQuery: typeof Router.query = undefined,
) => {
  const {
    // Bankrate, Morning Download
    sub1,
    sub2,
    // Bankrate
    sub3,
    sub4,
    // Nerdwallet
    utm_medium,
    utm_id,
    utm_content,
    // Moneywise, Consumer Voice
    subid,
    // US News, money.com, investopedia
    source_id,
    // MoneyTalksNews, MyBankTracker, US News, money.com, investopedia
    click_id,
    // Forbes, USA Today, CNN, investor.com & Expertise
    transID,
    sourceID,
    pageID,
    // Fortune Recommends
    PageID,
    // Betterbuck, FinanceBuzz
    clickID,
    // Betterbuck
    subID,
    // investor.com
    campaign,
    // Ours
    hosted_source,
    // FinanceBuzz, Kiplinger
    source,
    // Yep Ads, Synergy, Joywallet, Hearst, 24/7 Wallstreet, Morning Download, Prime Women, Moby
    Clickid,
    // Yep Ads
    Affid,
    Subaffid,
    // Synergy, Joywallet
    Subid,
    // 24/7 Wallstreet
    PartnerID,
  } = routerQuery || Router.query;

  let query = '';
  const queryObject: Record<string, typeof sub1> = {
    sub1,
    sub2,
    sub3,
    sub4,
    utm_medium,
    utm_id,
    utm_content,
    subid,
    source_id,
    click_id,
    transID,
    sourceID,
    pageID,
    PageID,
    clickID,
    subID,
    campaign,
    hosted_source,
    source,
    Clickid,
    Affid,
    Subaffid,
    Subid,
    PartnerID,
  };

  const getStringFromQuery = (
    param: string,
    paramValue: string | string[],
  ): string => {
    let stringValue = '';

    if (typeof paramValue === 'string') {
      stringValue += `&${param}=${paramValue}`;
    } else {
      paramValue.forEach((value) => {
        stringValue += `&${param}=${value}`;
      });
    }

    return stringValue;
  };

  // Remove empty params
  Object.keys(queryObject).forEach((param) => {
    if (!queryObject[param]) {
      delete queryObject[param];
    } else {
      query += getStringFromQuery(param, queryObject[param]);
    }
  });

  return {
    object: queryObject,
    query,
  };
};

/**
 * Register user in core and execute fake login
 */
export const auth0GetMatch: WebAuthMatchProps = (
  {body, loginWithRedirect, testingFormVersion, password},
  callback,
) => {
  const hubspotMeta = createHubspotMeta();
  const clientId = getGAClientId();
  const finalBody = {...body};

  // Tracking parameters from any FAAF embedded in an iframe
  const hostedParams = getHostedParams();

  // Add Google tag Manager "client id" if available
  if (clientId) {
    finalBody.google_client_id = clientId;
  }

  // Sends tracking query parameters to user creation
  finalBody.onboarding_tracking_params = JSON.stringify(hostedParams.object);

  request<UserCreationResponse>('/api/onboarding-register', {
    method: 'POST',
    headers: {
      formVersion: testingFormVersion || 'A',
    },
    body: {...finalBody, meta: hubspotMeta},
  })
    .then(async (registerRes) => {
      let authorizeUrl: string;

      await loginWithRedirect({
        openUrl(url) {
          authorizeUrl = url;
        },
      }).catch((e) => e);

      // NOTE: Passing true as the third parameter to `auth0JS` will add the `match` query param to the redirect URL
      const {WebAuth, nonce} = auth0JS({
        authorizeUrl,
        match: true,
      });

      WebAuth.login(
        {
          email: body.email,
          password: registerRes.password || password,
          nonce,
        },
        callback,
      );
    })
    .catch((error) => callback(error));
};
