import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Auth0Provider} from '@auth0/auth0-react';
import {NextRouter, useRouter} from 'next/router';

import {DEFAULT_REDIRECT_AFTER_LOGIN} from '../../constants';
import {getHostedParams} from '../../utils/onboardingUtils';

export interface FakeLoginProviderProps {
  domain: string;
  clientId: string;
  scope: string;
  audience: string;
}

const generateReturnTo = ({query}: NextRouter) => {
  let returnTo = (query.returnTo as string) || DEFAULT_REDIRECT_AFTER_LOGIN;

  /**
   * Pass bankrate query params through (if FAAF embedded in iframe)
   * Encoding ampersand (&) to avoid breaking query string
   */
  let hostedParams = getHostedParams(query).query;

  if (hostedParams.startsWith('&')) {
    hostedParams = getHostedParams(query).query.replace('&', '?');
  }

  if (hostedParams) {
    returnTo += encodeURIComponent(hostedParams);
  }

  return returnTo;
};

/**
 * Auth0 Provider wrapper for all pages including "fake login" logic.
 * Must receive config with a `getServerSideProps` function from the parent page
 * because all Auth0 ENV variables are not available in the client side (SSR).
 *
 * @param {string} domain - Auth0 domain
 * @param {string} clientId - Auth0 client ID
 * @param {string} scope - Auth0 scope
 * @param {string} audience - Auth0 audience
 * @param {ReactNode} children - React children
 */
const FakeLoginProvider: FC<PropsWithChildren<FakeLoginProviderProps>> = ({
  domain,
  clientId,
  scope,
  audience,
  children,
}) => {
  const router = useRouter();

  const [redirectUri, setRedirectUri] = useState<string>();

  useEffect(() => {
    if (!router.isReady) return;

    const newReturnTo = generateReturnTo(router);

    setRedirectUri(
      `${window.location.origin}/api/auth/login?customReturnTo=${newReturnTo}`,
    );
  }, [router]);

  return (
    !!redirectUri && (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          scope,
          audience,
          redirect_uri: redirectUri,
        }}
        auth0Client={{
          name: 'portal',
          version: undefined,
        }}
        useRefreshTokens>
        {children}
      </Auth0Provider>
    )
  );
};

export default FakeLoginProvider;
