import React, {FC} from 'react';
import {GetServerSidePropsContext} from 'next';

import LoginPassword, {
  getServerSideProps as GSSP,
  LoginProps,
} from '../src/modules/Login';
import FakeLoginProvider, {
  FakeLoginProviderProps,
} from '../stores/FakeLoginProvider';

const Login: FC<LoginProps & FakeLoginProviderProps> = ({
  domain,
  clientId,
  scope,
  audience,
  ...props
}) => (
  <FakeLoginProvider
    domain={domain}
    clientId={clientId}
    scope={scope}
    audience={audience}>
    <LoginPassword {...props} />
  </FakeLoginProvider>
);

export default Login;

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const data = await GSSP(ctx);

  return {
    ...data,
    props: {
      domain: process.env.AUTH0_ISSUER_BASE_URL,
      clientId: process.env.AUTH0_CLIENT_ID,
      scope: process.env.AUTH0_SCOPE,
      audience: process.env.AUTH0_AUDIENCE,
      ...data.props,
    },
  };
};
